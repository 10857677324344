.xl-input {
  width: 100%;
}

body {
  font-family: "Graphik LC Web";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

//GRAPHIK

@font-face {
  font-family: "Graphik Web";
  src: url("../../fonts/graphik/Graphik-Black-Web.eot");
  src: url("../../fonts/graphik/Graphik-Black-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Black-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Black-Web {
  font-family: "Graphik Web";
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../../fonts/graphik/Graphik-Bold-Web.eot");
  src: url("../../fonts/graphik/Graphik-Bold-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Bold-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Bold-Web {
  font-family: "Graphik Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../../fonts/graphik/Graphik-Medium-Web.eot");
  src: url("../../fonts/graphik/Graphik-Medium-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: "Graphik Web";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("../../fonts/graphik/Graphik-Regular-Web.eot");
  src: url("../../fonts/graphik/Graphik-Regular-Web.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-family: "Graphik LC Web";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.bold {
  font-weight: bold;
}

a.normal-a {
  color: inherit;
}

.full-height {
  height: 100%;
}

.center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-overflow {
  overflow-y: scroll;
  height: 1500px;
}

//Red, green, warning cubes in the reports
.cube {
  width: 16px;
  height: 16px;
}

.cube.success {
  background-color: #d2edb6;
}
.cube.warning {
  background-color: #ede4b6;
}
.cube.danger {
  background-color: #edc3b6;
}

.tab-pane.active {
  background-color: white;
  background-color: white;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top: transparent;
  border-color: #dee2e6 #dee2e6 #dee2e6;
}

.border-row {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-right: transparent;
  border-left: transparent;
  border-color: #dee2e6 #dee2e6 #dee2e6;
}

.score-round {
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  border-radius: 90px;
  background-color: #f0f0f0;
  margin: auto;
  line-height: 100px;
  font-size: 35px;
  font-weight: 900;
}

.score-label {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);
  display: table;
  margin-top: 10px;
}

.category-link {
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.icon-score {
  width: 18px;
  max-width: 18px;
}

.no-top-border {
  border-top: none;
}

.no-bottom-border {
  border-bottom: none;
}

.text-title {
  font-weight: 900;
}

.dark-background {
  background-color: #f0f0f0;
  border: 7px solid white;
  border-top: none;
  border-bottom: none;
}

.card-grey {
  background-color: #f0f0f0;
  color: #63799b !important;
}

.text-dark-blue {
  color: #63799b !important;
}

.category-text {
  font-size: 20px;
}

.top-text {
  font-size: 30px;
}

.max-height {
  max-height: 300px;
  text-align: center;
  margin: auto;
}

.serious-link {
  color: inherit;
  text-decoration: underline;
}

.disabled-grayscale {
  filter: opacity(0.8) grayscale(0.7);
}

.small-score-label {
  font-size: 10px;
  font-weight: 700;
  margin-top: 3px;
}

.success-label {
  background-color: #d9f5bd;
}

.fail-label {
  background-color: #efdcd6;
}

.warning-label {
  background-color: #fdf1cb;
}

input {
  border-radius: 25px !important;
}

.tabs-design {
  width: 23%;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  font-weight: bold;
}

.tabs-design a {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
  background-color: #dedee2;
  color: #63799b !important;
}

.tabs-design a.active {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.input-group > input {
  border-radius: 25px 0px 0px 25px !important;
}

.input-group-text {
  border-radius: 0px 25px 25px 0px !important;
}

.card-img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.6;
}

.input-blue-bg {
  background-color: #f3f5f7 !important;
}

.search-table {
  width: 200px;
}

.small-error {
  color: red;
  margin-bottom: 0.75rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #7500c0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7500c0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  height: 100% !important;
}

.clickable-rows .rdt_TableRow {
  cursor: pointer;
}

.prev {
  font-size: 2rem;
  margin-bottom: 1rem;
  -webkit-text-stroke: 5px white;
}

.badge {
  font-size: 93%;
}

.caret-s {
  top: 16px;
  position: absolute;
  right: 10px;
  transition: all 0.15s ease 0s;
}

.caret-s.up {
  transform: rotate(180deg);
}

.sidebar .nav li.collapse-open > a {
  opacity: 1;
}

.logo {
  text-align: center;
  width: 100%;
}

.logo a {
  margin: auto;
  width: 50%;
}

.admin-hr {
  text-align: center;
  color: white;
}
.admin-hr:before {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.425);
  position: absolute;
  width: 50%;
  top: 220px;
  left: -45px;
}

.admin-hr:after {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.425);
  width: 50%;
  position: absolute;
  top: 220px;
  right: -45px;
}

.col-0 {
  flex: 0 0 0% !important;
  transition: all 500ms ease;
  opacity: 0;
  width: 0;
}

.img-thumb {
  position: relative;
  overflow: hidden;
  padding: 10px;
}
.img-thumb img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  background: black;
}

.thumbcontainer {
  display: flex;
  flex-wrap: wrap;
}

.square:before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.dropzone h3 {
  text-align: center;
}

.dz-active h3 {
  color: #7500c0;
}

.more-sm {
  font-size: 2rem;
  color: #7500c0;
}

.more-big {
  font-size: 4rem;
  color: #7500c0;
}

.hide {
  opacity: 0;
  transition: all 1s ease;
}

.fade-out {
  -webkit-animation: fade-out 400ms ease-out both;
  animation: fade-out 400ms ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-8-5 17:16:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-5 17:17:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.thumbcontainer .progress {
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  z-index: 10;
}

.img-thumb.inprogress img {
  filter: brightness(50%);
}

.img-thumb.loaded img {
  filter: brightness(100%);
}

.img-thumb video {
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.form-up {
  z-index: 13;
}

.form-create {
  display: none;
}

.form-active {
  display: block !important;
}

.search-input-icon {
  color: #7500c0;
  font-size: 1.8rem;
}

.thumbcontainer-url img {
  margin-top: 2rem;
}

.spinner-rotate {
  -webkit-animation: rotate-center 2s linear infinite both;
  animation: rotate-center 2s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-11 15:46:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.azure-bg {
  background-color: #f3f5f7;
  width: 100vw;
  height: 100vh;
}

.error-bg {
  background-color: #07242c;
  width: 100vw;
  height: 100vh;
}

.spinner-loggin {
  font-size: 5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-h {
  text-align: center;
  button {
    border: 1px solid white;
    color: white;
    background-color: #fff0;
  }
  button:hover,
  button:focus,
  button:active {
    background-color: rgba(255, 255, 255, 0.226) !important;
  }

  h2 {
    font-size: 8rem;
    color: #7500c0;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.admin-sep {
  text-align: center;
  width: 100%;
  color: white;
}
.admin-sep::after {
  content: "\2002\2002\2014\2014\2014";
}
.admin-sep::before {
  content: "\2014\2014\2014\2002\2002";
}

.cursorp {
  cursor: pointer;
}

.task-pending img,
.task-error img {
  filter: brightness(30%);
}

img {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.task-spinner {
  position: absolute;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  color: #be82ff;
  font-size: 4rem;
}

.task-error-icon-container {
  position: absolute;
  z-index: 10;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  color: #ff5959;
  font-weight: 700;
  transform: translate(-50%, -50%);
  text-align: center;
}

.task-error-icon {
  font-size: 4rem;
}

.ul-hide {
  padding: 0;
  list-style-type: none;
}

.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0 120px;
}

.full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  border-bottom: none !important;
  margin-top: 20px;
}

.navbar.navbar-transparent.home .nav-item {
  .nav-link {
    color: #3a3937 !important;
  }

  &.active .nav-link {
    color: #a100ff !important;
  }
}

.nav-item {
  cursor: pointer;
}
.navbar-text-dark .nav-link {
  color: #66615b !important;
}
.navbar.navbar-transparent .nav-item .nav-link {
  color: #66615b !important;
}

.full-page .container {
  z-index: 1200;
  position: relative;
}
.card-login {
  max-width: 400px;
  margin: auto;
}

.big-card-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2.2rem;
  font-weight: 500;
}

.center-btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.form-login {
  padding-top: 1.8rem;
  .btn {
    margin-top: 1.8rem;
  }
}

.img-acn {
  max-width: 150px;
}

.card-login label,
.card-register label {
  color: #424141;
}

.text-register {
  font-size: 0.8rem;
  text-align: center;
}

.link {
  color: #7500c0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.card-register {
  max-width: 800px;
  margin: auto;
}

.title-info {
  font-weight: 600;
}
.rdt_TableCol_Sortable {
  font-size: 1rem;
  font-weight: 500;
  color: #7500c0;
}

.block-info {
  line-height: 1.7rem;
}

.project-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.date-card {
  font-size: 0.6rem;
  font-style: italic;
}

.icon-card {
  font-size: 1.1rem;
  color: #979797;
}

.card-img-alt {
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

.method-icon {
  font-size: 1.7rem;
  width: 1em !important;
  color: #979797;
  height: 1em !important;
  // background-color: rgba(224, 222, 222, 0.877);
  // padding: 0.2rem;
  // border-radius: 5px;
  &.url {
    color: $info-color;
  }
  &.chrome {
    color: $orange-color;
  }
  &.api {
    color: $purple-color;
  }
  &.adobe {
    color: #570303;
  }
  &.upload {
    color: #b8ab00;
  }
  &.dom {
    color: $success-color;
  }
  &.image {
    color: #66615b;
  }
  &.video {
    color: $danger-color;
  }
}

.container-icon-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bordered-col {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.title-info-task {
  font-weight: 600;
  font-size: 0.8rem;
}

.task-title {
  font-size: 0.8rem;
  font-weight: 500;
  font-style: italic;
}

.card-img-video {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

#page-title {
  font-size: 2rem;
  color: #66615b;
}

h5 .badge {
  font-size: 0.82rem !important;
}

.wrapper-quotas {
  padding: 0.5rem;
}

.chevron-height{
  height: 100%;
  position: absolute;
}

.chevron-height-right{
  height: 100%;
  position: absolute;
  right:0;
}

.chevron-right {
  position: sticky;
    font-size: 45px;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    overflow: auto;
    margin: auto;
    text-shadow: 1px 4px 4px #7171717d;
    cursor: pointer;
}

.chevron-left {
  position: sticky;
    font-size: 45px;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    overflow: auto;
    margin: auto;
    text-shadow: 1px 4px 4px #7171717d;
    cursor: pointer;
}



.chevron-spacing{
  width: 25px;
  display: inline-table;
}

.container-scroll {
  overflow-y: auto;
  display: flex;
  width: 100%;
  scroll-behavior: smooth;
}

.gradient-right {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 4%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 40%,
    #f3f5f7 80%
  );
}

.gradient-left {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 4%;
  background: linear-gradient(to right, #f3f5f7 0%, rgba(255, 255, 255, 0) 40%);
}

.btn-feedback {
  position: fixed;
  left: 99.2%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  z-index: 3000;
}

.feedback-check-label {
  height: auto;
  background-color: #dddad5;
  display: inline-block;
  padding: 1rem;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.feedback-form input[type="radio"] {
  opacity: 0;
}

.feedback-form input[type="radio"]:checked + .feedback-check-label {
  background-color: #7500c0;
  color: white;
}

.feedback-icon {
  font-size: 1.6rem;
  margin-bottom: 0.3rem;
}

.dz-raw {
  margin-top: 1.4rem;
  padding: 1rem;
  border: dashed orange 2px;
}

.img-sm {
  width: 5rem;
}

.form-create-check {
  font-size: 1.1rem;
  label {
    color: #a100ff;
  }

  .disabled-txt {
    color: #d1d1d1;
  }

  .form-check-sign.disabled::before {
    background-color: #dbdbdb;
  }

  input[type="checkbox"]:checked + .form-check-sign.disabled:before {
    background-color: #a100ff4b;
  }
  input[type="checkbox"]:checked + .form-check-sign:before {
    background-color: #a100ff;
  }
}

.warning {
  color: #7500c0;
}

.success {
  color: #00a838;
}

.video-result {
  width: 100% !important;
}

.white-bg {
  background-color: white !important;
}

.score-memo {
  font-size: 1.2rem;
  text-align: center;
}

.bg-yellow {
  background-color: rgb(221, 217, 0) !important;
}

.bg-purple {
  background-color: #972ad6;
}

.name-html {
  position: absolute;
  z-index: 12;
  top: 4%;
  left: 15%;
  width: 70%;
  text-align: center;
  font-size: 1rem;
  color: #cc75ff;
}

.autocomplete {
  background-color: #efefef;
  border-radius: 6px;
  padding-top: 5px;
  list-style-type: none;
  position: absolute;
  width: 94%;
}

a.disabled {
  pointer-events: none;
}
.react-select__control--menu-is-open,
.react-select__control--is-focused {
  border-color: #972ad6 !important;
  box-shadow: none !important;
}

.react-select__option:hover,
.react-select__option--is-focused {
  background-color: #972ad621 !important;
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.rendering-container {
  position: relative;
  top: 0;
  left: 0;

  flex-wrap: wrap;
}

.rendering-content-container {
  position: absolute;

  overflow: auto;

  &.mobile {
    left: 6.5%;
    top: 11%;
    width: 87%;
    height: 78%;
  }

  &.tablet {
    left: 4.2%;
    top: 5.2%;
    width: 92%;
    height: 88.6%;
  }

  &.desktop {
    left: 11.3%;
    top: 7.2%;
    width: 77.6%;
    height: 84.4%;
  }
}

.rendering-content-container::-webkit-scrollbar {
  display: none;
}

.rendering-device {
  position: relative;
  top: 0;
  left: 0;
}

.modal-rendering {
  & > .modal-content {
    background-color: transparent;
    border: none;
    height: 80%;
  }
}

.progress-text {
  position: absolute;
  text-align: center;
  line-height: 16px;
  overflow: hidden;
  color: #000;
  right: 0;
  left: 0;
  top: 0;
}

.text-menu p {
  font-size: 1.3rem !important ;
  color: white;
}

.text-menu .nav-item.active .nav-link p {
  color: white !important;
  text-decoration: underline;
}

.logo-card {
  position: absolute;
  top: -100px;
  left: 50%;
  margin-left: -70px;
  width: 150px !important;
  height: 150px;
}

.quiet-a {
  color: #000 !important;
}

.project-name {
  font-size: 0.9rem;
}

.badge-task {
  font-size: 0.5rem !important;
  background-color: white;
  border: solid 1px #972ad6;
  color: #972ad6;
  &.active {
    background-color: #972ad6;
    border: solid 1px #972ad6;
    color: white;
  }
  line-height: 1.2;
}

.col-19 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
}

.info-task {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
  width: 20%;
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  color: white;
  font-size: 0.75rem;
  height: 30%;
  clip-path: polygon(
    0 100%,
    100% 0,
    100% 0,
    100% 50%,
    100% 100%,
    100% 100%,
    10% 100%,
    0% 100%,
    0% 50%
  );
  background-color: #972ad6;
  cursor: pointer;
  & > .row {
    display: none;
  }

  & > svg {
    position: absolute;
    top: 44%;
    left: 65%;
    color: white;
    font-size: 1vw;
  }

  &.info-out {
    cursor: pointer;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    -webkit-animation: info-out 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: info-out 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    & > .row {
      display: none;
    }
  }

  &.info-in {
    cursor: default;
    padding-left: 2vw;
    padding-top: 1.7vw;
    padding-right: 1vw;
    display: block !important;
    -webkit-animation: info-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: info-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    & > svg {
      display: none;
    }
    & > .row {
      display: flex;
    }
  }
}

@-webkit-keyframes info-out {
  100% {
    width: 20%;
    height: 30%;
    color: #972ad6;
    clip-path: polygon(
      0 100%,
      100% 0,
      100% 0,
      100% 50%,
      100% 100%,
      100% 100%,
      10% 100%,
      0% 100%,
      0% 50%
    );
  }
  0% {
    width: 100%;
    height: 100%;
  }
}
@keyframes info-out {
  100% {
    width: 20%;
    height: 30%;
    color: #972ad6;
    clip-path: polygon(
      0 100%,
      100% 0,
      100% 0,
      100% 50%,
      100% 100%,
      100% 100%,
      10% 100%,
      0% 100%,
      0% 50%
    );
  }
  0% {
    width: 100%;
    height: 100%;
    color: white;
  }
}

@-webkit-keyframes info-in {
  0% {
    width: 20%;
    height: 30%;
  }
  100% {
    width: 100%;
    height: 100%;
    clip-path: polygon(
      0 33%,
      22% 0,
      100% 0,
      100% 10%,
      100% 100%,
      100% 100%,
      10% 100%,
      0% 100%,
      0% 10%
    );
  }
}
@keyframes info-in {
  0% {
    widows: 20%;
    height: 30%;
    color: #972ad6;
  }
  100% {
    width: 100%;
    height: 100%;
    color: white;
    clip-path: polygon(
      0 33%,
      22% 0,
      100% 0,
      100% 10%,
      100% 100%,
      100% 100%,
      10% 100%,
      0% 100%,
      0% 10%
    );
  }
}

.not-specified {
  color: #acacac;
}

.sidebar::after {
  background-image: linear-gradient(to bottom, #07242cc7, #07242cc7),
    url("/img/bg.jpg") !important;
  background-position: center !important;
}

.cardimg-project-container > span {
  width: 100%;
}


.info-icon {
  margin-left:0.3rem;
  color:#3a3937;
  cursor: pointer;

  &:hover {
    opacity:0.7;
  }
}

.sticky-menu {
  align-self: flex-start;
  top: 50px;
  position: -webkit-sticky;
  position: sticky;
}

