/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url('/client/src/assets/fonts/nucleo-icons.eot');
  src: url('/client/src/assets/fonts/nucleo-icons.eot') format('embedded-opentype'), url('/client/src/assets/fonts/nucleo-icons.woff2') format('woff2'), url('/client/src/assets/fonts/nucleo-icons.woff') format('woff'), url('/client/src/assets/fonts/nucleo-icons.ttf');
  font-weight: normal;
  font-style: normal;
}
